

export const FooterDataWhatWeDo = [
    {
        title : "Website Development",
        link : '#'
    },
    {
        title : "Software Development",
        link : '#'
    },
    {
        title : "Mobile App Development",
        link : '#'
    },
    {
        title : "Digital Marketing Services",
        link : '#'
    },

]
export const FooterDataCompanyLinks = [
    {
        title : "About Us",
        link : '#'
    },
    {
        title : "Contact Us",
        link : '#'
    },
    {
        title : "Careers",
        link : '#'
    },
    {
        title : "Book an Appoinment",
        link : '#'
    },

]
export const FooterDataQuickLinks = [
    {
        title : "Careers",
        link : '#'
    },
    {
        title : "Disclaimer",
        link : '#'
    },
    {
        title : "Privacy & Policy",
        link : '#'
    },
    {
        title : "Terms & Conditions",
        link : '#'
    },

]
